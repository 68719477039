import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Button, Alert } from "reactstrap";
import { MenuItemsRepeater } from "./menu-items-repeater";
import Axios from "axios";
import API from "../../config.json";
import { v4 as uuidv4 } from "uuid";
import S3 from "react-aws-s3";
import { IMAGEKIT_URL } from "../../helpers/constants";
import { generateMenuObject } from "../../helpers/utils";

export const TableRepeaterFull = ({
  update,
  rows = [],
  updateState,
  storeId,
  driverId,
  driverKey,
  menuType,
  refresh,
}) => {
  const [modal, setModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [idx, setIdx] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    if (!modal && isAdd) {
      let row = JSON.parse(JSON.stringify(rows));
      row.splice(idx, 1);
      updateState({
        rows: row,
      });

      setIsAdd(false);
    }
  }, [modal, isAdd]);

  const toggle = () => {
    setModal((current) => !current);
    setErrorMsg("");
  };

  const renderMenuOptions = (options) =>
    options.map((option, idx) => (
      <Fragment key={idx}>
        <tr className="table-danger">
          <td></td>
          <td>{option.title}</td>
          <td>
            {option.subTitle} ({option.limit})
          </td>
          <td></td>
          <td></td>
        </tr>

        <>
          {option.popupMenuOptions &&
            renderPopUpOptions(option.popupMenuOptions)}
        </>
      </Fragment>
    ));

  const renderPopUpOptions = (popupMenuOptions) =>
    popupMenuOptions.map((popupMenuOption, idx) => (
      <tr className="table-warning" key={idx}>
        <td></td>
        <td></td>
        <td>
          {popupMenuOption.name} (${popupMenuOption.price})
        </td>
        <td></td>
        <td></td>
      </tr>
    ));

  return (
    <>
      <div className="table-responsive">
        <Table className="table table-centered table-nowrap table-sm table-hover">
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Old price</th>
              <th>Sub category</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {rows.length &&
              rows.map((item, key) => {
                return (
                  <Fragment key={"_order_" + key}>
                    <tr
                      className={
                        item.notAvailable ? "table-warning" : "table-success"
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.price}</td>
                      <td>{item.oldPrice}</td>
                      <td>
                        {item.menuType && Object.keys(item.menuType) && (
                          <>
                            {item.menuType?.value
                              ? item.menuType.value
                              : item.menuType}
                          </>
                        )}
                      </td>
                      <td>{item.image ? "Image found" : "No image"}</td>
                    </tr>
                    {renderMenuOptions(item.menuOptions)}
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};
